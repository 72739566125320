.customContainer {
  display: flex;
  justify-content: start;
  align-items: start;
}
.customLeftSidebar {
  width: 20%;
  padding-top: 115px;
}
.customrightSidebar {
  width: 20%;
  padding-top: 115px;
}
.middlesection {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 116px;
}
.addsmiddle {
  margin-bottom: 30px;
  margin-top: 20px;
}
.addsmiddle img {
  width: 100%;
  object-fit: cover;
}
.custompadding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.addsImage {
  border: 1px solid hsl(0, 0%, 80%);
  padding: 10px;
  border-radius: 5px;
}
.customSearchbtn {
  height: 40px;
  padding: 0px;
  text-align: center;
  line-height: 40px;
}
.adsImageFit {
  height: 100%;
}
.adsImageFit img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: "800px") {
  .customContainer {
    display: block;
  }
  .customrightSidebar {
    width: 100%;
  }
  .customLeftSidebar {
    width: 100%;
  }
}
