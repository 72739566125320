.parent{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}
.child1{
    width: 20%;
    overflow-y: auto;
    overflow-x: hidden;
}
.child2{
    width: 80%;
    border-left: 1px solid black;

}
.parentChat{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.childChat-1{
    border-bottom: 1px solid black;
    height: 90%;
    /*background: pink;*/
    padding: 1%;
    overflow: auto;
}
.childChat-2{

    height: 10%;
    /*background: lightblue;*/
    padding: 1%;
}
.recieveMsg{
    width: 60%;
    background: lightgreen;
    float: left;
    padding: 1%;
    border-radius: 5px 5px 5px 5px;
}
.sendMsg{
    width: 60%;
    background: lightblue;
    float: right;
    padding: 1%;
    border-radius: 5px 5px 5px 5px;
}
.chatButtons{
    outline: none;
    border: none;
    padding: 2%;
    font-size: 30px;
    border-radius: 5px;
    color: blue;
    cursor: pointer;
    background: white;
}
.userImage{
    height: 50px;
    width: 80px;
}
.userName{
    padding-top: 10%;
}
.userInfo{
    border-bottom: 1px solid black;
    padding: 3%;
    cursor: pointer;
}










/*------------*/
.single-feature .thumb img {
    height: 300px;
}